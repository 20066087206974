@tailwind base;
@tailwind components;
@tailwind utilities;


.blog {
  @apply text-gray-500;
  font-family: "Roboto", sans-serif;
}

.blog-preview {
  max-height: 800px;
}

.blog h1 {
  @apply mt-2 mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl;
}

.blog h2 {
  @apply text-gray-900 mt-6 mb-5;
}

.blog h3 {
  @apply text-gray-900 mt-6;
}

.blog h4 {
  @apply block text-base text-center text-c-orange-abundo font-semibold tracking-wide uppercase;
}

.blog h6 {
  @apply mt-8 text-xl text-gray-500 leading-8;
}

.blog p {
  @apply mb-5;
}

.blog strong {
  @apply text-gray-900;
}

.blog ul {
  @apply list-disc mb-5 pl-5;
}

.blog ol {
  @apply list-decimal mb-5 pl-5;
}

.blog li p {
  @apply my-1;
}

.blog a {
  @apply text-c-hyperlink underline;
}

.blog blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}