@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor {
  @apply text-gray-800 min-h-[100px] transition duration-200 ease-in py-6 px-2;
}

.ql-editor p {
  @apply text-base font-normal
}
.ql-editor ul {
  @apply list-disc list-inside;
}

.ql-editor ol {
  @apply list-decimal list-inside;
}

.ql-editor li {
  @apply my-1 text-base;
}
.ql-editor h1 {
  @apply text-6xl leading-9 font-semibold;
}

.ql-editor h2 {
  @apply text-5xl leading-7 font-semibold;
}

.ql-editor h3 {
  @apply text-4xl leading-6 font-semibold;
}

.ql-editor h4 {
  @apply text-3xl leading-4 font-medium;
}

.ql-editor h5 {
  @apply text-2xl leading-normal font-medium;
}

.ql-editor h6 {
  @apply text-2xl leading-normal font-medium
}
.quill {
  @apply rounded-xl  bg-white   ;
}

.ql-toolbar {
  @apply bg-gray-50 text-gray-600 rounded-t-lg sticky top-0 z-10 ;
}

.ql-header {
  @apply text-gray-800 p-1;
}

.ql-container {
  @apply flex-1 rounded-b-lg px-5 ;
}
