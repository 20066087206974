@tailwind base;
@tailwind components;
@tailwind utilities;

/* Markdown notes */


 .notes h1 {
  @apply block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl;
}

  .notes h2 {
  @apply text-gray-900;
}

  .notes h3 {
  @apply text-gray-900;
}

  .notes h4 {
  @apply block text-base text-center text-c-orange-abundo font-semibold tracking-wide uppercase;
}

 .notes  h6 {
  @apply text-xl text-gray-900 leading-8;
}

  .notes p {
  @apply text-left py-1
}

 .notes  strong {
  @apply text-gray-900;
}

  .notes ul {
  @apply list-disc  pl-5;
}

  .notes ol {
  @apply list-decimal  pl-5;
}

  .notes li p {
  @apply my-1;
}

  .notes a {
  @apply text-c-hyperlink underline;
}

 .notes  blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}